import React, { useEffect } from "react";
import { useHelperStore } from "store/store";

function LoadingStateChanger() {
  const { increaseLoadingCounter, decreaseLoadingCounter } = useHelperStore();

  useEffect(() => {
    increaseLoadingCounter();
    return () => {
      setTimeout(function () {
        decreaseLoadingCounter();
      }, 20);
    };
  }, []);

  return <></>;
}

export default LoadingStateChanger;
