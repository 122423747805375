import { METHOD } from "constants/apiMethods";
import { useApiHelper } from "hooks/useApiHelper";
import { useSnackbar } from "hooks/useSnackbar";
import { useAccountStorePersist, useHelperStore } from "store/store";
import { useNavigate } from "hooks/useNavigate";

export const useApi = () => {
  // hooks
  const { enqueueMessage } = useSnackbar();
  const { apiGET, apiPOST, apiPATCH, apiDELETE, apiFILEDOWNLOAD } = useApiHelper();
  const { navigate } = useNavigate();

  /* STORES */
  const { increaseLoadingCounter, decreaseLoadingCounter } = useHelperStore();
  const { topBarCompanySelectedValue } = useAccountStorePersist();

  async function apiCall({ url = "", method = METHOD.GET_PRIVATE, data = {}, includeSearchParam = true, loadingScreen = true }) {
    try {
      if (loadingScreen) increaseLoadingCounter();
      if (includeSearchParam) url = `${url}?company_id=${topBarCompanySelectedValue.company_id}`;
      switch (method) {
        case METHOD.GET_PRIVATE:
          return await (
            await apiGET(url, true)
          ).data;
        case METHOD.GET_PUBLIC:
          return await (
            await apiGET(url, false)
          ).data;
        case METHOD.POST:
          return await (
            await apiPOST(url, data)
          ).data;
        case METHOD.PATCH:
          return await (
            await apiPATCH(url, data)
          ).data;
        case METHOD.DELETE:
          return await (
            await apiDELETE(url, data)
          ).data;
        case METHOD.FILEDOWNLOAD:
          try {
            const res = await (await apiFILEDOWNLOAD(url, data)).data;
            const link = document.createElement("a");
            link.href = res.url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            if (error?.response?.data?.message) enqueueMessage(error?.response?.data?.message, "error");
            else enqueueMessage("Can't download document", "error");
          }
          return;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message && error?.response?.data?.message === "redirect") {
        enqueueMessage("Redirecting from restricted content", "error");
        navigate(`/?company_id=${topBarCompanySelectedValue.company_id}`);
      } else if (error?.response?.data?.message) enqueueMessage(error.response.data.message, "error");
      else if (error?.response?.data) enqueueMessage(error.response.data, "error");
      else if (error?.message === "Network Error") enqueueMessage("Server can't be reached", "error");
      else enqueueMessage(error.toString(), "error");
    } finally {
      if (loadingScreen) decreaseLoadingCounter();
    }
  }

  return {
    apiCall,
    enqueueMessage,
    // METHODS
    M_GET_PRIVATE: METHOD.GET_PRIVATE,
    M_GET_PUBLIC: METHOD.GET_PUBLIC,
    M_POST: METHOD.POST,
    M_PATCH: METHOD.PATCH,
    M_DELETE: METHOD.DELETE,
    M_FILEDOWNLOAD: METHOD.FILEDOWNLOAD,
  };
};
