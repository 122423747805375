import React from "react";
import {
  Grid as MuiGrid,
  Stack as MuiStack,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Tooltip,
  TextField as MuiTextField,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CloseIcon } from "./Icons";

/**
 * @direction row, column
 * @alignItems flex-start, center, flex-end, stretch, baseline
 * @justifyContent flex-start, center, flex-end, space-between, space-around, space-evenly
 */
export const GridContainer = ({ children, container = true, direction = "row", alignItems = "center", justifyContent = "center", style = {}, spacing = 0 }) => {
  return (
    <MuiGrid style={style} container={container} direction={direction} alignItems={alignItems} justifyContent={justifyContent} spacing={spacing}>
      {children}
    </MuiGrid>
  );
};

/**
 * @direction row, column
 * @alignItems flex-start, center, flex-end, stretch, baseline
 * @justifyContent flex-start, center, flex-end, space-between, space-around, space-evenly
 */
export const GridItem = ({
  children,
  container = true,
  direction = "row",
  item = true,
  xs = true,
  alignItems = "center",
  justifyContent = "center",
  style = {},
}) => {
  return (
    <MuiGrid style={style} container={container} direction={direction} item={item} xs={xs} alignItems={alignItems} justifyContent={justifyContent}>
      {children}
    </MuiGrid>
  );
};

export const Stack = ({ children, spacing = 1, direction = "column" }) => {
  return (
    <MuiStack spacing={spacing} direction={direction}>
      {children}
    </MuiStack>
  );
};

export const Button = ({
  loading = null,
  onClick,
  size = "small",
  color = "info",
  variant = "contained",
  buttonText = "ButtonText",
  style = {},
  autofocus = false,
  disabled = false,
  fullWidth = false,
}) => {
  return (
    <>
      {loading === null ? (
        <MuiButton size={size} color={color} variant={variant} onClick={onClick} autoFocus={autofocus} style={style} disabled={disabled} fullWidth={fullWidth}>
          {buttonText}
        </MuiButton>
      ) : (
        <LoadingButton
          loading={loading}
          size={size}
          color={color}
          variant={variant}
          onClick={onClick}
          autoFocus={autofocus}
          style={style}
          disabled={disabled}
          fullWidth={fullWidth}
        >
          {buttonText}
        </LoadingButton>
      )}
    </>
  );
};

export const IconButton = ({ onClick, size = "small", icon, tooltip = "", disabled = false, tooltipPlacement = "bottom", style = {} }) => {
  return (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <span>
        <MuiIconButton disabled={disabled} size={size} onClick={onClick} style={style}>
          {icon}
        </MuiIconButton>
      </span>
    </Tooltip>
  );
};

export const TextField = ({
  label = "Enter value",
  value = "",
  onChange,
  placeholder = "",
  type = "string",
  size = "small",
  variant = "outlined",
  margin = "dense",
  disabled = false,
  fullWidth = false,
  multiline = false,
  minRows = 2,
  maxRows = 15,
  autoFocus = false,
  style = {},
}) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <MuiTextField
      label={label}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      size={size}
      type={type}
      variant={variant}
      margin={margin}
      disabled={disabled}
      fullWidth={fullWidth}
      minRows={minRows}
      maxRows={maxRows}
      multiline={multiline}
      autoFocus={autoFocus}
      style={style}
    />
  );
};

export const Dialog = ({
  open,
  onClose,
  onConfirm = onClose,
  dialogTitle,
  dialogContentText = undefined,
  dialogContent = undefined,
  confirmText = "Confirm",
  cancelText = "Cancel",
  confirmAction = <Button autoFocus={true} onClick={onConfirm} buttonText={confirmText} style={{ marginLeft: "15px" }} />,
  cancelAction = <Button onClick={onClose} variant="text" color="primary" buttonText={cancelText} style={{ marginLeft: "15px" }} />,
  closeAction = <IconButton onClick={onClose} icon={<CloseIcon />} tooltip="Close" style={{ position: "absolute", right: 10, top: 24 }} />,
  confirmNote = "",
}) => {
  return (
    <MuiDialog maxWidth="1000" style={{ marginLeft: "230px", minWidth: "1270px" }} open={open} onClose={onClose}>
      <DialogTitle style={{ marginRight: "15px", marginTop: "10px", marginBottom: "10px" }}>
        {dialogTitle}
        {closeAction}
      </DialogTitle>
      {dialogContentText || dialogContent ? (
        <DialogContent dividers>
          {dialogContentText ? <DialogContentText>{dialogContentText}</DialogContentText> : null}
          {dialogContent ? dialogContent : null}
        </DialogContent>
      ) : null}
      <DialogActions style={{ padding: "20px" }}>
        {confirmNote}
        {cancelAction} {confirmAction}
      </DialogActions>
    </MuiDialog>
  );
};

export const Checkbox = ({ label = "Checkbox label", checked = false, onChange, size = "small", style = {}, disabled = false }) => {
  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <>
      {label === "" ? (
        <MuiCheckbox checked={checked} onChange={handleChange} size={size} disabled={disabled} />
      ) : (
        <FormControlLabel label={label} control={<MuiCheckbox checked={checked} onChange={handleChange} size={size} disabled={disabled} />} style={style} />
      )}
    </>
  );
};
