import { useSnackbar as useSnackbar_notistack } from "notistack";

export const useSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar_notistack();

  async function enqueueMessage(message = "", variant = "success") {
    enqueueSnackbar(message, { variant: variant });
  }

  // usage:
  // const { enqueueMessage } = useSnackbar();
  // enqueueMessage("Message to be displayed")

  return {
    enqueueMessage,
  };
};
