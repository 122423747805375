import { useNavigate as useNavigateDefault } from "react-router-dom";

export const useNavigate = () => {
  let _navigate = useNavigateDefault();

  function navigate(toURL = "") {
    _navigate(`${toURL}`);
  }

  return {
    navigate,
  };
};
