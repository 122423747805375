import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_SPA_CLIENTID,
    authorizationParams: {
      audience: `${process.env.REACT_APP_API_URL}/`,
      redirect_uri: window.location.origin,
    },
    useRefreshTokens: true,
  };

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(process.env.REACT_APP_AUTH0_DOMAIN && process.env.REACT_APP_AUTH0_SPA_CLIENTID && window.location.origin)) {
    return null;
  }

  return (
    <Auth0Provider {...providerConfig} onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
