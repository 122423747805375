import { CssBaseline } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import Layout from "views/_appLayout/Layout";
import Loading from "components/general/Loading";
import Auth0ProviderWithNavigate from "./Auth0ProviderWithNavigate";

const App = () => {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      style={{ marginLeft: "230px" }}
    >
      <CssBaseline enableColorScheme />
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <Layout />
          <Loading />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export default App;
