import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Avatar, Chip, Grid, Menu, MenuItem, Toolbar, Tooltip, IconButton, Badge, Typography } from "@mui/material";
import { GridContainer, GridItem } from "components/mui/Components";
import ME_LOGO_LEFT from "assets/ME_LOGO_LEFT.png";
import company_test_logo from "assets/company_test_logo.png";
import { useNavigate } from "hooks/useNavigate";
import React, { useRef, useState } from "react";
import CompanyList from "views/_appLayout/topBar/CompanyList";
import LanguageSelection from "views/_appLayout/topBar/LanguageSelection";
import { MonitorIcon, NotificationIcon, PreviousIcon, TokenIcon, SOHIcon, ComplaintIcon, ProjectIcon, AddIcon } from "components/mui/Icons";

export default function TopBar({ topBarCompanyList }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef(null);
  const { user, logout } = useAuth0();
  const { navigate } = useNavigate();

  const handleProfileMenuClick = (event) => {
    setAnchorEl(menuRef.current);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClicked = () => {
    navigate(`/profile`);
    handleProfileMenuClose();
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    handleProfileMenuClose();
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ display: "flex", minWidth: "1000px" }}>
        <div style={{ minWidth: "260px", textAlign: "center" }}>
          <img src={ME_LOGO_LEFT} alt="MeasurEye logo" style={{ height: "50px", verticalAlign: "middle" }} />
        </div>
        {/* <div style={{ minWidth: "130px", marginLeft: "20px" }}>
          <GridItem direction="column">
            <Typography variant="body2" className="blue">
              Early Access
            </Typography>
          </GridItem>
        </div> */}
        <GridContainer>
          <GridItem xs={12} justifyContent="end">
            {/* <div style={{ marginLeft: "15px" }}></div>
            <img src={company_test_logo} alt="Company test logo" style={{ height: "45px", verticalAlign: "middle" }} />
            <div style={{ marginLeft: "15px" }}></div> */}
            <CompanyList topBarCompanyList={topBarCompanyList} />
            {/* <div style={{ marginLeft: "10px" }}></div>
            <LanguageSelection
              languageList={[
                { code: "EN", language: "English", flag: "(flag)" },
                { code: "DE", language: "Deutsch", flag: "(flag)" },
              ]}
            /> */}
            <Chip
              ref={menuRef}
              variant="outlined"
              label={user.email}
              onClick={handleProfileMenuClick}
              avatar={<Avatar src={user.picture} />}
              sx={{ height: "40px", marginLeft: "10px", borderRadius: "25px", fontSize: "small", minWidth: "170px", maxWidth: "170px" }}
            />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem onClick={handleProfileClicked}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </GridItem>
        </GridContainer>
      </Toolbar>
    </AppBar>
  );
}
