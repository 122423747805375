import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SmartphoneOutlinedIcon from "@mui/icons-material/SmartphoneOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import NetworkCheckOutlinedIcon from "@mui/icons-material/NetworkCheckOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import VibrationOutlinedIcon from "@mui/icons-material/VibrationOutlined";
import AirOutlinedIcon from "@mui/icons-material/AirOutlined";
import WindPowerOutlinedIcon from "@mui/icons-material/WindPowerOutlined";
import LocalAirportOutlinedIcon from "@mui/icons-material/LocalAirportOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import CleaningServicesOutlinedIcon from "@mui/icons-material/CleaningServicesOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ThunderstormOutlinedIcon from "@mui/icons-material/ThunderstormOutlined";
//import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import TocOutlinedIcon from "@mui/icons-material/TocOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import OnlinePredictionOutlinedIcon from "@mui/icons-material/OnlinePredictionOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import AudioFileOutlinedIcon from "@mui/icons-material/AudioFileOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import React from "react";

export const ProjectIcon = ({ color = "blue", fontSize = "small" }) => {
  return <WorkOutlineOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const MonitorIcon = ({ color = "blue", fontSize = "small" }) => {
  return <SmartphoneOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const IndicatorIcon = ({ color = "blue", fontSize = "small" }) => {
  return <NetworkCheckOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const NotificationIcon = ({ color = "blue", fontSize = "small" }) => {
  return <NotificationsOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const CloseIcon = ({ color = "blue", fontSize = "small" }) => {
  return <CloseOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const EditIcon = ({ color = "orange", fontSize = "small" }) => {
  return <EditOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const DeleteIcon = ({ color = "red", fontSize = "small" }) => {
  return <DeleteOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const SOHIcon = ({ color = "blue", fontSize = "small" }) => {
  return <HealthAndSafetyOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const ComplaintIcon = ({ color = "blue", fontSize = "small" }) => {
  return <ContactMailOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const PeopleIcon = ({ color = "blue", fontSize = "small" }) => {
  return <GroupsOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const ReportIcon = ({ color = "blue", fontSize = "small" }) => {
  return <AssessmentOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const TasksIcon = ({ color = "blue", fontSize = "small" }) => {
  return <FormatListNumberedOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const AddIcon = ({ color = "green", fontSize = "small" }) => {
  return <AddBoxOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const CalibrationDateIcon = ({ color = "blue", fontSize = "small" }) => {
  return <EventOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const TokenIcon = ({ color = "blue", fontSize = "small" }) => {
  return <MonetizationOnOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const HomeIcon = ({ color = "blue", fontSize = "small" }) => {
  return <ListAltOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const MoreIcon = ({ color = "blue", fontSize = "small" }) => {
  return <TocOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const DownloadIcon = ({ color = "blue", fontSize = "small" }) => {
  return <FileDownloadOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const OpenIcon = ({ color = "blue", fontSize = "small" }) => {
  return <LaunchOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const AudioIcon = ({ color = "blue", fontSize = "small" }) => {
  return <AudioFileOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const RightArrowIcon = ({ color = "blue", fontSize = "small" }) => {
  return <NavigateNextOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
// PROJECT TYPES
export const AirfieldIcon = ({ color = "blue", fontSize = "small" }) => {
  return <LocalAirportOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const ConstructionIcon = ({ color = "blue", fontSize = "small" }) => {
  return <ConstructionOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
// MONITOR TYPES
export const NoiseIcon = ({ color = "blue", fontSize = "small" }) => {
  return <KeyboardVoiceOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const VibrationIcon = ({ color = "blue", fontSize = "small" }) => {
  return <VibrationOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const AirqualityIcon = ({ color = "blue", fontSize = "small" }) => {
  return <AirOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const WeatherIcon = ({ color = "blue", fontSize = "small" }) => {
  return <ThunderstormOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
// INVOICES
export const InvoiceIcon = ({ color = "blue", fontSize = "small" }) => {
  return <ReceiptOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const CurrencyExchangeIcon = ({ color = "blue", fontSize = "small" }) => {
  return <CurrencyExchangeOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const DoneIcon = ({ color = "green", fontSize = "small" }) => {
  return <CheckOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
// OTHER
export const OnlineIcon = ({ color = "green", fontSize = "small" }) => {
  return <OnlinePredictionOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
export const PowerIcon = ({ color = "green", fontSize = "small" }) => {
  return <PowerSettingsNewOutlinedIcon style={{ verticalAlign: "middle" }} fontSize={fontSize} className={color} />;
};
