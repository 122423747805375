import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container } from "@mui/material";
import { LicenseManager } from "ag-grid-enterprise";
import LoadingStateChanger from "components/general/LoadingStateChanger";
//import "css/layout.css";
import { useApi } from "hooks/useApi";
import React, { useEffect, useState } from "react";
import { useAccountStore, useAccountStorePersist } from "store/store";
//import Content from "views/_appLayout/components/Content";
import Navigation from "views/_appLayout/navBar/Navigation";
import TopBar from "views/_appLayout/topBar/TopBar";
import { useSearchParams, useLocation } from "react-router-dom";

const Content = React.lazy(() => import("views/_appLayout/content/Content"));
const VerifyEmail = React.lazy(() => import("views/generic/VerifyEmail"));
const ErrorPage = React.lazy(() => import("views/generic/ErrorPage"));

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

const Layout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { isLoading, error, user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [isInitialLoadDone, setIsInitialLoadDone] = useState(false);
  const { apiCall } = useApi();
  const { topBarCompanySelectedValue, setTopBarCompanySelectedValue } = useAccountStorePersist();
  const { topBarCompanyList, setTopBarCompanyList, isRedirectingFromCompanyCreation, setIsRedirectingFromCompanyCreation } = useAccountStore();
  // const [toBeApproved, setToBeApproved] = useState();
  const [isBackendError, setIsBackendError] = useState(false);

  useEffect(() => {
    if (topBarCompanySelectedValue && searchParams.get("company_id") == null) {
      setSearchParams((prev) => {
        prev.set("company_id", topBarCompanySelectedValue?.company_id);
        return prev;
      });
    }
  }, [pathname, topBarCompanySelectedValue?.company_id]);

  // useEffect(() => {
  //   if (topBarCompanySelectedValue && searchParams.get("company_id") != null) {
  //     setSearchParams((prev) => {
  //       prev.set("server", topBarCompanySelectedValue.company_name);
  //       return prev;
  //     });
  //   }
  // }, [searchParams.get("company_id"), topBarCompanySelectedValue.company_id]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname + window.location.search,
        },
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (isAuthenticated && user.email_verified) {
      (async () => {
        const res = await apiCall({
          url: "/v1/getListOfCompanyForUser",
          includeSearchParam: false,
        });
        if (!!res) setTopBarCompanyList(res);
        else {
          setTopBarCompanyList([]);
          setIsBackendError(true);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // LOGIC Company TOP BAR SELECTION
  useEffect(() => {
    if (isAuthenticated && topBarCompanyList && topBarCompanyList.length > 0) {
      setIsInitialLoadDone(true);
      // navigating from email, should auto load the correct Company
      if (searchParams.get("company_id") != null && topBarCompanyList.findIndex((x) => x.company_id == searchParams.get("company_id")) !== -1) {
        // special case should be ignored when creating a company from the DEMO
        if (isRedirectingFromCompanyCreation) {
          setIsRedirectingFromCompanyCreation(false);
        } else {
          // set selected value
          setTopBarCompanySelectedValue(topBarCompanyList[topBarCompanyList.findIndex((x) => x.company_id == searchParams.get("company_id"))]);
          // reset searchParams
          // let searchParametersList = {};
          // for (const element of searchParams.entries()) {
          //   if (element[0] !== "company_id") searchParametersList[element[0]] = element[1];
          // }
          // setSearchParams(searchParametersList);
        }
      }
      // if persisted value does not exist, default to DEMO
      else if (!topBarCompanySelectedValue) {
        setTopBarCompanySelectedValue(topBarCompanyList[0]);
        setSearchParams((prev) => {
          prev.set("company_id", topBarCompanyList[0].company_id);
          return prev;
        });
      }
      // if not found, default to DEMO
      else if (!topBarCompanyList.find((x) => x.company_id == topBarCompanySelectedValue.company_id)) {
        setTopBarCompanySelectedValue(topBarCompanyList[0]);
        setSearchParams((prev) => {
          prev.set("company_id", topBarCompanyList[0].company_id);
          return prev;
        });
      }
      // else just set company_id
      else {
        setSearchParams((prev) => {
          prev.set("company_id", topBarCompanySelectedValue.company_id);
          return prev;
        });
      }
      setIsInitialLoadDone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topBarCompanyList]);

  if (error)
    return (
      <>
        <React.Suspense fallback={<LoadingStateChanger />}>
          <ErrorPage errorMessage={error.message} />
        </React.Suspense>{" "}
      </>
    );
  if (isLoading || !user || isInitialLoadDone) return <LoadingStateChanger />;
  if (!user.email_verified)
    return (
      <>
        <React.Suspense fallback={<LoadingStateChanger />}>
          <VerifyEmail />
        </React.Suspense>{" "}
      </>
    );
  if (!isBackendError && (!topBarCompanyList || !topBarCompanySelectedValue)) return <LoadingStateChanger />;
  if (topBarCompanyList.length === 0 || !topBarCompanySelectedValue.company_id)
    return (
      <>
        <React.Suspense fallback={<LoadingStateChanger />}>
          <ErrorPage errorMessage={`Could not fetch the users company list`} />
        </React.Suspense>{" "}
      </>
    );
  return (
    <Box sx={{ display: "flex", minWidth: "1500px", minHeight: "800px" }}>
      <React.Suspense fallback={<LoadingStateChanger />}>
        <TopBar topBarCompanyList={topBarCompanyList} />
        <Navigation />
        <React.Suspense fallback={<LoadingStateChanger />}>
          <Content />
        </React.Suspense>
      </React.Suspense>
    </Box>
  );
};

export default Layout;
