// import
import { create } from "zustand";
import { persist } from "zustand/middleware";

// stores
let accountStorePersist = (set) => ({
  topBarCompanySelectedValue: undefined,
  setTopBarCompanySelectedValue: (value) => set({ topBarCompanySelectedValue: value }),
});

let accountStore = (set) => ({
  topBarCompanyList: undefined,
  setTopBarCompanyList: (value) => set({ topBarCompanyList: value }),
  isRedirectingFromCompanyCreation: false,
  setIsRedirectingFromCompanyCreation: (value) => set({ isRedirectingFromCompanyCreation: value }),
  /* QUICK ACCESS IN PROJECT/MONITOR MENU CATEGORY */
  navigatorProjectList: undefined,
  setNavigatorProjectList: (value) => set({ navigatorProjectList: value }),
  navigatorMonitorList: undefined,
  setNavigatorMonitorList: (value) => set({ navigatorMonitorList: value }),
  navigatorSelectedProject: null,
  setNavigatorSelectedProject: (value) => set({ navigatorSelectedProject: value }),
  navigatorSelectedMonitor: null,
  setNavigatorSelectedMonitor: (value) => set({ navigatorSelectedMonitor: value }),
  /* TAB INDEXES FOR THE PROJECT/MONITOR MENU CATEGORY */
  tabIndexProjectAllRealtimeData: 0,
  setTabIndexProjectAllRealtimeData: (value) => set({ tabIndexProjectAllRealtimeData: value }),
  tabIndexProjectSingleRealtimeData: 0,
  setTabIndexProjectSingleRealtimeData: (value) => set({ tabIndexProjectSingleRealtimeData: value }),
  tabIndexProjectMonitorRealtimeData: 0,
  setTabIndexProjectMonitorRealtimeData: (value) => set({ tabIndexProjectMonitorRealtimeData: value }),
  tabIndexProjectMonitorHistoricalData: 0,
  setTabIndexProjectMonitorHistoricalData: (value) => set({ tabIndexProjectMonitorHistoricalData: value }),
  tabIndexProjectSingleAudience: 0,
  setTabIndexProjectSingleAudience: (value) => set({ tabIndexProjectSingleAudience: value }),
  tabIndexProjectSingleDashboard: 0,
  setTabIndexProjectSingleDashboard: (value) => set({ tabIndexProjectSingleDashboard: value }),
  tabIndexProjectSingleConfiguration: 1,
  setTabIndexProjectSingleConfiguration: (value) => set({ tabIndexProjectSingleConfiguration: value }),
  tabIndexProjectMonitorConfiguration: 0,
  setTabIndexProjectMonitorConfiguration: (value) => set({ tabIndexProjectMonitorConfiguration: value }),
  /* OTHER */
  dashboardTodoCount: 0,
  setDashboardTodoCount: (value) => set({ dashboardTodoCount: value }),
  hostedInvoiceURL: "",
  setHostedInvoiceURL: (value) => set({ hostedInvoiceURL: value }),
});

let helperStore = (set) => ({
  loadingCounter: 0,
  increaseLoadingCounter: () => set((state) => ({ loadingCounter: state.loadingCounter + 1 })),
  decreaseLoadingCounter: () => set((state) => ({ loadingCounter: state.loadingCounter - 1 })),
  reloadIndicatorListState: 0,
  reloadIndicatorList: () => set((state) => ({ reloadIndicatorListState: state.reloadIndicatorListState + 1 })),
});

// middleware
accountStorePersist = persist(accountStorePersist, { name: "account" });

// export
export const useAccountStorePersist = create(accountStorePersist);
export const useAccountStore = create(accountStore);

export const useHelperStore = create(helperStore);
