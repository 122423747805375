import { Backdrop, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHelperStore } from "store/store";

function Loading() {
  const { loadingCounter } = useHelperStore();
  const [isLoading, setIsLoading] = useState(false);

  /*
  useEffect(() => {
    if (loadingCounter > 0) setIsLoading(true);
    else setIsLoading(false);
  }, [loadingCounter]);
*/

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 999999999 }} open={loadingCounter > 0} transitionDuration={400}>
        <CircularProgress size={70} />
      </Backdrop>
    </>
  );
}

export default Loading;
