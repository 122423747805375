import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DomainAddOutlinedIcon from "@mui/icons-material/DomainAddOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import HailOutlinedIcon from "@mui/icons-material/HailOutlined";
import PestControlOutlinedIcon from "@mui/icons-material/PestControlOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import TapAndPlayOutlinedIcon from "@mui/icons-material/TapAndPlayOutlined";
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LogoDevOutlinedIcon from "@mui/icons-material/LogoDevOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import SosOutlinedIcon from "@mui/icons-material/SosOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined";
import FiberNewOutlinedIcon from "@mui/icons-material/FiberNewOutlined";

import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { Box, Drawer, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Grid, Typography, Tooltip, Divider } from "@mui/material";
import { GridContainer, GridItem } from "components/mui/Components";
import { AddCompanyIcon } from "components/mui/Icons";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAccountStorePersist, useAccountStore } from "store/store";
import { useApi } from "hooks/useApi";
import { useAuth0 } from "@auth0/auth0-react";

const drawerWidth = 230;

export default function Navigation() {
  const { topBarCompanySelectedValue } = useAccountStorePersist();
  const {
    dashboardTodoCount,
    setDashboardTodoCount,
    tabIndexProjectSingleRealtimeData,
    tabIndexProjectAllRealtimeData,
    tabIndexProjectMonitorConfiguration,
    tabIndexProjectMonitorHistoricalData,
    tabIndexProjectMonitorRealtimeData,
    tabIndexProjectSingleAudience,
    tabIndexProjectSingleConfiguration,
    navigatorSelectedProject,
    navigatorSelectedMonitor,
  } = useAccountStore();
  const [navigationMenu, setNavigationMenu] = useState([]);
  const [companyTokenAmount, setCompanyTokenAmount] = useState(0);
  const { user } = useAuth0();
  const { apiCall } = useApi();
  // const MINUTE_MS = 1000 * 60 * 30; // 30 minutes refresh

  //   const getDashboardTodoCount = () => {
  //     (async () => {
  //       let res;
  //       if (topBarCompanySelectedValue.orgCode === "c") res = await apiCall({ url: "/v1/getDashboardTodoCountClient", loadingScreen: false });
  //       else if (topBarCompanySelectedValue.orgCode === "s") res = await apiCall({ url: "/v1/getDashboardTodoCountSupplier", loadingScreen: false });
  //       if (res) setDashboardTodoCount(res);
  //       else setDashboardTodoCount(0);
  //     })();
  //   };

  //   useEffect(() => {
  //     // run on page load
  //     getDashboardTodoCount();
  //     // run every 30 minutes afterwards..
  //     const interval = setInterval(() => {
  //       getDashboardTodoCount();
  //     }, MINUTE_MS);
  //     return () => clearInterval(interval);
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [topBarCompanySelectedValue]);

  useEffect(() => {
    (async () => {
      const res = await apiCall({ url: "/v1/getCompanyTokenAmount", loadingScreen: false });
      if (res) setCompanyTokenAmount(res);
      else setCompanyTokenAmount(0);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topBarCompanySelectedValue]);

  useEffect(() => {
    const project_id = navigatorSelectedProject?.project_id;
    const monitor_id = navigatorSelectedMonitor?.monitor_id;
    const navLinkToDashboard = project_id ? `/dashboard/${project_id}` : `/dashboard`;

    const navLinkToConfiguration =
      project_id && monitor_id ? `/configuration/${project_id}/${monitor_id}` : project_id ? `/configuration/${project_id}` : `/configuration`;
    const navLinkToRealtimeData = project_id ? `/real-time-data/${project_id}` : `/real-time-data`;
    const navLinkToHistoricalData =
      project_id && monitor_id ? `/historical-data/${project_id}/${monitor_id}` : project_id ? `/historical-data/${project_id}` : `/historical-data`;

    const navLinkToReports = project_id ? `/reports/${project_id}` : `/reports`;
    const navLinkToAudience = project_id ? `/audience/${project_id}` : `/audience`;

    const _navMenu = [
      {
        headerName: "Projects / Monitors",
        menuItems: [
          // {
          //   text: "Dashboard",
          //   icon: <DashboardOutlinedIcon />,
          //   navLinkTo: navLinkToDashboard,
          //   withDivider: true,
          // },
          {
            text: "Real-time Data",
            icon: <OndemandVideoOutlinedIcon />,
            navLinkTo: navLinkToRealtimeData,
          },
          {
            text: "Historical Data",
            icon: <ManageSearchOutlinedIcon />,
            navLinkTo: navLinkToHistoricalData,
            withDivider: true,
          },
          {
            text: "Configuration",
            icon: <EditOutlinedIcon />,
            navLinkTo: navLinkToConfiguration,
          },
          {
            text: "Reports",
            icon: <AssessmentOutlinedIcon />,
            navLinkTo: navLinkToReports,
          },
          // {
          //   text: "Audience",
          //   icon: <GroupsOutlinedIcon />,
          //   navLinkTo: navLinkToAudience,
          // },
        ],
      },
      {
        headerName: "Administration",
        menuItems: [
          {
            text: "Company",
            icon: <BusinessOutlinedIcon />,
            navLinkTo: "/company",
          },
          // {
          //   text: "My Profile",
          //   icon: <HailOutlinedIcon />,
          //   navLinkTo: "/profile",
          // },
          // {
          //   text: "Tokens",
          //   icon: <MonetizationOnOutlinedIcon className="blue" />,
          //   navLinkTo: "/token",
          // },
        ],
      },
      // {
      //   headerName: "Subscription",
      //   menuItems: [
      //     {
      //       text: "Tokens",
      //       icon: <MonetizationOnOutlinedIcon className="blue" />,
      //       navLinkTo: "/token",
      //     },
      //   ],
      // },
      // {
      //   headerName: "Connectivity",
      //   menuItems: [
      //     {
      //       text: "API",
      //       icon: <IntegrationInstructionsOutlinedIcon />,
      //       navLinkTo: "/developer-api",
      //     },
      //   ],
      // },
      {
        headerName: "Support",
        menuItems: [
          {
            text: "Get Help",
            icon: <SosOutlinedIcon />,
            navLinkTo: "/get-help",
          },
          {
            text: "Learn",
            icon: <SchoolOutlinedIcon />,
            navLinkTo: "/learn",
          },
          {
            text: "Release Notes",
            icon: <SpeakerNotesOutlinedIcon />,
            navLinkTo: "/release-notes",
          },
        ],
      },
    ];
    // if (user.email.endsWith("@nti-audio.com")) {
    //   _navMenu.push({
    //     headerName: "NTi Internal",
    //     menuItems: [
    //       {
    //         text: "Admin Dashboard",
    //         icon: <AdminPanelSettingsOutlinedIcon />,
    //         navLinkTo: "/admin-dashboard",
    //       },
    //       // {
    //       //   text: "Activity logs",
    //       //   icon: <LogoDevOutlinedIcon />,
    //       //   navLinkTo: "/activity-logs",
    //       // },
    //     ],
    //   });
    // }
    setNavigationMenu(_navMenu);
  }, [topBarCompanySelectedValue, navigatorSelectedProject, navigatorSelectedMonitor]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        {topBarCompanySelectedValue.company_name === "DEMO" && (
          <List subheader={<ListSubheader>DEMO is active...</ListSubheader>}>
            <ListItemButton component={NavLink} to={`/create-company`} dense className="navigationCreateCompanyMenu">
              <ListItemIcon>
                <DomainAddOutlinedIcon fontSize="medium" className="green" />
              </ListItemIcon>
              <ListItemText primary="Create Company" />
            </ListItemButton>
          </List>
        )}
        {navigationMenu.map((section, index) => (
          <List key={section.headerName} subheader={<ListSubheader>{section.headerName}</ListSubheader>}>
            {section.menuItems.map((menuItem, index) => (
              <React.Fragment key={menuItem.navLinkTo}>
                <ListItemButton component={NavLink} to={`${menuItem.navLinkTo}`} dense>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.text} />
                  {menuItem.text === "Tokens" && (
                    <GridContainer
                      style={{
                        margin: "0px",
                        padding: "0px",
                        mindWidth: "30px",
                        width: "30px",
                        height: "30px",
                        minHeight: "30px",
                        verticalAlign: "middle",
                      }}
                    >
                      <Tooltip title={`Available Tokens: ${companyTokenAmount}`}>
                        <Typography className="blue" variant="body2" style={{ lineHeight: "1" }}>
                          {companyTokenAmount}
                        </Typography>
                      </Tooltip>
                    </GridContainer>
                  )}
                </ListItemButton>
                {menuItem.withDivider && <Divider style={{ margin: "5px auto", width: "80%" }} />}
              </React.Fragment>
            ))}
          </List>
        ))}
        {/* {[
          // NOTE: ALSO CHANGE IN THE CONTENT.JS FILE
          "steve@nti-audio.com",
          "t.hupp@nti-audio.com",
          "d.zanatta@nti-audio.com",
          "g.schmidle@nti-audio.com",
          "b.besselmann@nti-audio.de",
          "b.macmillan@nti-audio.com",
          "r.stillmaker@nti-audio.com",
          "e.xian@nti-audio.com",
          "e.aflalo@nti-audio.com",
          "j.burillier@nti-audio.com",
          "p.arce@nti-audio.com",
          "c.greene@nti-audio.com",
        ].includes(user.email) && (
          <List subheader={<ListSubheader>NTi Audio</ListSubheader>}>
            <ListItemButton component={NavLink} to={`/nti-audio-sales`} dense>
              <ListItemIcon>
                <LeaderboardOutlinedIcon fontSize="medium" className="orange" />
              </ListItemIcon>
              <ListItemText primary="Sales" />
            </ListItemButton>
          </List>
        )} */}
      </Box>
    </Drawer>
  );
}
