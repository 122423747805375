import { Autocomplete, TextField, Box, Tooltip } from "@mui/material";
import React from "react";
import { useAccountStorePersist } from "store/store";
import { useSearchParams } from "react-router-dom";

export default function CompanyList({ topBarCompanyList }) {
  const { topBarCompanySelectedValue, setTopBarCompanySelectedValue } = useAccountStorePersist();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, value, reason) => {
    if (reason === "selectOption") {
      setTopBarCompanySelectedValue(value);
      setSearchParams((prev) => {
        prev.set("company_id", value.company_id);
        return prev;
      });
    }
  };

  return (
    <Autocomplete
      disableClearable
      className="autocompleteStyle"
      value={topBarCompanySelectedValue}
      options={topBarCompanyList}
      getOptionLabel={(option) => option.company_name || ""}
      sx={{ width: 170 }}
      renderInput={(params) => <TextField {...params} size="small" label="Company" sx={{ width: 170 }} />}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => {
        return option.company_id === value.company_id;
      }}
      renderOption={(props, option, state, ownerState) => (
        <Tooltip title={`${option.company_name || ""}`} {...props} placement="bottom">
          <div>{option.company_name || ""}</div>
        </Tooltip>
      )}
    />
  );
}
