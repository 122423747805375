import { createTheme } from "@mui/material";
import { Tooltip, Box } from "@mui/material";
import React from "react";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#42a5f5",
    },
    secondary: {
      main: "#ffa726",
    },
    error: {
      main: "#ef5350",
    },
    warning: {
      main: "#ffa726",
    },
    info: {
      main: "#90caf9",
    },
    success: {
      main: "#66bb6a",
    },
    divider: "rgba(33,150,243,0.15)",
    background: {
      paper: "#e3f2fd",
      default: "#f4faff",
    },
  },
  shape: {
    borderRadius: 25,
  },
  typography: {
    h6: {
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#bbdefb",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          height: "25px",
          paddingTop: "5px",
          lineHeight: "1.3",
          fontSize: "smaller",
          backgroundColor: "#bbdefb",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
        option: {
          fontSize: "small",
          height: "35px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
        paper: {
          minWidth: "300px",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          height: "28px",
          borderRadius: "25px",
          margin: "0 5px",
          "&.active": {
            backgroundColor: "#bbdefb",
          },
        },
      },
    },
  },
});
/*
const theme = createTheme({
  palette: {
    primary: {
      main: "#3eacf0",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          background: "linear-gradient(#6bc2da, #e4f9ff)",
          "&.Mui-disabled": { backgroundColor: "#e7e7e7 !important", background: "none" },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: { color: "black", "&.Mui-active": { color: "#93cedf" }, "&.Mui-completed": { color: "#93cedf" } },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { "&.Mui-focused .MuiOutlinedInput-notchedOutline": { borderColor: "#93cedf", borderWidth: "1px", color: "#93cedf" } },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          height: "25px",
          lineHeight: "1.5",
        },
        option: {
          fontSize: "small",
          height: "20px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: { padding: 0 },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: { padding: "0px 2px" },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#e4f9ff !important",
          boxShadow: "inset 0px 0px 15px 0px #6bc2da !important",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.active": {
            backgroundColor: "#e4f9ff",
            boxShadow: "inset 0px 0px 15px 0px #6bc2da",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: "20px",
          backgroundColor: "#e4f9ff",
        },
      },
    },
  },
});
*/

export default theme;
