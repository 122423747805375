import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
//const axios = require("axios").default;
//const axios = require("axios");
import axios from "axios";

export const useApiHelper = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  // TODO server numbering logic... /1
  const apiURL = `${process.env.REACT_APP_API_URL}/1`;

  // usage:
  //  const { state, apiDELETE, loading } = useApi();
  //  const response = await apiDELETE("/delete");

  async function getTokenSilently() {
    const accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_API_URL}/` });
    setToken(accessToken);
    return accessToken;
  }

  async function apiGET(url, privateRoute = false) {
    if (privateRoute) {
      return axios({
        method: "get",
        url: `${apiURL}${url}`,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${await getTokenSilently()}`,
        },
      });
    } else {
      return axios({
        method: "get",
        url: `${apiURL}${url}`,
        withCredentials: true,
      });
    }
  }

  async function apiPOST(url, data = {}) {
    return axios({
      method: "post",
      url: `${apiURL}${url}`,
      data: { ...data },
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${await getTokenSilently()}`,
      },
    });
  }

  async function apiFILEDOWNLOAD(url, data = {}) {
    return axios({
      method: "post",
      url: `${apiURL}${url}`,
      data: { ...data },
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${await getTokenSilently()}`,
        //"Content-Disposition": "attachment",
      },
      //responseType: "blob",
    });
  }

  async function apiPATCH(url, data = {}) {
    return axios({
      method: "patch",
      url: `${apiURL}${url}`,
      data: { ...data },
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${await getTokenSilently()}`,
      },
    });
  }

  async function apiDELETE(url, data = {}) {
    return axios({
      method: "delete",
      url: `${apiURL}${url}`,
      data: { ...data },
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${await getTokenSilently()}`,
      },
    });
  }

  return {
    token,
    getTokenSilently,
    apiGET,
    apiPOST,
    apiPATCH,
    apiDELETE,
    apiFILEDOWNLOAD,
  };
};
